import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import SignIn from "./Components/Auth/SignIn";
import AppLayout from "./Components/Layout/AppLayout";
import { UnAuthenticatedRoute, AuthenticatedRoute } from "./utils/Route";
import Studies from "./Components/Studies/Studies";
import ChangePassword from "./Components/Auth/ChangePassword";
import Error404 from "./Components/Layout/404";
import CustomSignIn from "./Components/Auth/CustomSignIn";
import SurveyWrapper from "./Components/Survey/SurveyWrapper";
import ThankYouWrapper from "./Components/Survey/ThankYouWrapper";
import QuestionPrintPhase from "./Components/QuestionBuilder/QuestionPrint/QuestionPrintPhase";
import QuestionPrintForm from "./Components/QuestionBuilder/QuestionPrint/QuestionPrintForm";
import OTPVerification from "./Components/Auth/OTPVerification";
import UserRegistration from "./Components/Auth/UserRegistration";
import WebviewWrapper from "./Components/CMS/Webview/WebviewWrapper";

const routes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <UnAuthenticatedRoute>
        <Outlet />
      </UnAuthenticatedRoute>
    ),
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "login/:slug",
        element: <CustomSignIn />,
      },
      {
        path: "register/:slug",
        element: <UserRegistration />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "signup",
        element: <SetPassword />,
      },
      {
        path: "2fa",
        element: <OTPVerification />,
      },
    ],
  },
  {
    path: "/profile/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/app",
    element: (
      <AuthenticatedRoute>
        <AppLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "studies",
        element: <Studies />,
      },
      {
        path: "organisation",
        children: [
          {
            index: true,
            lazy: async () => {
              const { OrganisationsComp } = await import("./Routes/OrgRoutes");
              return { Component: OrganisationsComp };
            },
          },
          {
            path: "builder/:id",
            lazy: async () => {
              const { OrgBuilderComp } = await import("./Routes/OrgRoutes");
              return { Component: OrgBuilderComp };
            },
          },
          {
            path: "view/:id",
            lazy: async () => {
              const { OrgViewComp } = await import("./Routes/OrgRoutes");
              return { Component: OrgViewComp };
            },
          },
        ],
      },
      {
        path: "features",
        lazy: async () => {
          const { FeaturesComp } = await import("./Routes/AppRoutes");
          return { Component: FeaturesComp };
        },
      },
      {
        path: "oauth",
        lazy: async () => {
          const { OAuthComp } = await import("./Routes/AppRoutes");
          return { Component: OAuthComp };
        },
      },
      {
        path: "platform",
        children: [
          {
            path: "users",
            lazy: async () => {
              const { PlatformUsersComp } = await import("./Routes/AppRoutes");
              return { Component: PlatformUsersComp };
            },
          },
        ],
      },
      {
        path: "settings/:id",
        lazy: async () => {
          const { OrgSettingsComp } = await import("./Routes/OrgRoutes");
          return { Component: OrgSettingsComp };
        },
      },
      {
        path: "users",
        children: [
          {
            index: true,
            lazy: async () => {
              const { OrgUsersComp } = await import("./Routes/OrgRoutes");
              return { Component: OrgUsersComp };
            },
          },
          {
            path: "details/:id",
            lazy: async () => {
              const { OrgUserDetailsComp } = await import("./Routes/OrgRoutes");
              return { Component: OrgUserDetailsComp };
            },
          },
        ],
      },
    ],
  },
  {
    path: "surveys/:surveySlug",
    element: <SurveyWrapper />,
  },
  {
    path: "surveys/:surveySlug/thank-you",
    element: <ThankYouWrapper />,
  },
  {
    path: "404",
    element: <Error404 />,
  },
  {
    path: "print/study/:id/phase/:type",
    element: (
      <AuthenticatedRoute>
        <QuestionPrintPhase />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "print/study/:id/form/:formId",
    element: (
      <AuthenticatedRoute>
        <QuestionPrintForm />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/studies/:id",
    lazy: async () => {
      const { StudyLayoutComp } = await import("./Routes/StudyRoutes");
      return { Component: StudyLayoutComp };
    },
    children: [
      {
        path: "dashboard",
        lazy: async () => {
          const { DashboardComp } = await import("./Routes/StudyRoutes");
          return { Component: DashboardComp };
        },
      },
      {
        path: "study-designer",
        children: [
          {
            index: true,
            lazy: async () => {
              const { StudyDesignerComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: StudyDesignerComp };
            },
          },
          {
            path: "form/:formId/edit",
            lazy: async () => {
              const { QuestionBuilderComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: QuestionBuilderComp };
            },
          },
          {
            path: "form/:formId/edit-translation",
            lazy: async () => {
              const { QuestionBuilderTranslationComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: QuestionBuilderTranslationComp };
            },
          },
          {
            path: "form/:formId/preview",
            lazy: async () => {
              const { QuestionPreviewComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: QuestionPreviewComp };
            },
          },
        ],
      },
      {
        path: "participants",
        children: [
          {
            index: true,
            lazy: async () => {
              const { RecordsComp } = await import("./Routes/StudyRoutes");
              return { Component: RecordsComp };
            },
          },
          {
            path: ":participantId",
            children: [
              // {
              //   path: "details",
              //   lazy: async () => {
              //     const { ParticipantDetailsComp } = await import(
              //       "./Routes/StudyRoutes"
              //     );
              //     return { Component: ParticipantDetailsComp };
              //   },
              // },
              {
                index: true,
                lazy: async () => {
                  const { ParticipantTabsComp } = await import(
                    "./Routes/StudyRoutes"
                  );
                  return { Component: ParticipantTabsComp };
                },
              },
              {
                path: "view/questionnaire/:formId/attempt/:attemptId",
                lazy: async () => {
                  const { QuestionAttemptPreview } = await import(
                    "./Routes/StudyRoutes"
                  );
                  return { Component: QuestionAttemptPreview };
                },
              },
              {
                path: "responses",
                lazy: async () => {
                  const { ResponsesComp } = await import(
                    "./Routes/StudyRoutes"
                  );
                  return { Component: ResponsesComp };
                },
              },
              {
                path: "prescribe-meal",
                lazy: async () => {
                  const { PrescribeMealComp } = await import(
                    "./Routes/StudyRoutes"
                  );
                  return { Component: PrescribeMealComp };
                },
              },
            ],
          },
          {
            path: ":participantId/prescription",
            lazy: async () => {
              const { PrescriptionComp } = await import("./Routes/StudyRoutes");
              return { Component: PrescriptionComp };
            },
          },
        ],
      },
      {
        path: "participant-groups",
        lazy: async () => {
          const { ParticipantGroupsComp } = await import(
            "./Routes/StudyRoutes"
          );
          return { Component: ParticipantGroupsComp };
        },
      },
      {
        path: "audit-trail",
        lazy: async () => {
          const { AuditTrailComp } = await import("./Routes/StudyRoutes");
          return { Component: AuditTrailComp };
        },
      },
      {
        path: "export",
        lazy: async () => {
          const { ExportComp } = await import("./Routes/StudyRoutes");
          return { Component: ExportComp };
        },
      },
      {
        path: "study-settings",
        lazy: async () => {
          const { StudySettingsComp } = await import("./Routes/SettingsRoutes");
          return { Component: StudySettingsComp };
        },
      },
      {
        path: "study-theme",
        lazy: async () => {
          const { StudyThemePanelComp } = await import(
            "./Routes/SettingsRoutes"
          );
          return { Component: StudyThemePanelComp };
        },
      },
      {
        path: "integrations",
        lazy: async () => {
          const { IntegrationsComp } = await import("./Routes/SettingsRoutes");
          return { Component: IntegrationsComp };
        },
      },
      {
        path: "wearable",
        children: [
          {
            index: true,
            lazy: async () => {
              const { WearablesComp } = await import("./Routes/SettingsRoutes");
              return { Component: WearablesComp };
            },
          },
          {
            path: "config/:type/:systemId",
            lazy: async () => {
              const { WearablesConfigComp } = await import(
                "./Routes/SettingsRoutes"
              );
              return { Component: WearablesConfigComp };
            },
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            lazy: async () => {
              const { UsersComp } = await import("./Routes/AdminRoutes");
              return { Component: UsersComp };
            },
          },
          {
            path: ":userId",
            lazy: async () => {
              const { UserDetailComp } = await import("./Routes/AdminRoutes");
              return { Component: UserDetailComp };
            },
          },
        ],
      },

      {
        path: "form-permissions",
        lazy: async () => {
          const { FormPermissionsComp } = await import("./Routes/AdminRoutes");
          return { Component: FormPermissionsComp };
        },
      },
      {
        path: "permissions",
        lazy: async () => {
          const { RolesListComp } = await import("./Routes/AdminRoutes");
          return { Component: RolesListComp };
        },
      },
      {
        path: "food",
        children: [
          {
            index: true,
            lazy: async () => {
              const { FoodComp } = await import("./Routes/ModuleRoutes");
              return { Component: FoodComp };
            },
          },
          {
            path: "recipe/:recipeId",
            lazy: async () => {
              const { CreateNewRecipeComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: CreateNewRecipeComp };
            },
          },
          {
            path: "template/:templateId",
            lazy: async () => {
              const { TemplateBuilderComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: TemplateBuilderComp };
            },
          },
        ],
      },
      {
        path: "exercise",
        children: [
          {
            index: true,
            lazy: async () => {
              const { ExerciseCollectionComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: ExerciseCollectionComp };
            },
          },
          {
            path: ":exerciseId/categories",
            lazy: async () => {
              const { ExerciseCollectionDetailsComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: ExerciseCollectionDetailsComp };
            },
          },
          {
            path: ":exerciseId/categories/:categoryId/sections",
            lazy: async () => {
              const { SectionBuilderComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: SectionBuilderComp };
            },
          },
          {
            path: "builder",
            lazy: async () => {
              const { ExerciseBuilderComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: ExerciseBuilderComp };
            },
          },
        ],
      },

      {
        path: "cms",
        children: [
          {
            index: true,
            lazy: async () => {
              const { CMSComp } = await import("./Routes/ModuleRoutes");
              return { Component: CMSComp };
            },
          },
          {
            path: "learning/:categoryId",
            lazy: async () => {
              const { LearningModuleDetailsComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: LearningModuleDetailsComp };
            },
          },
          {
            path: ":moduleId",
            lazy: async () => {
              const { UnitsComp } = await import("./Routes/ModuleRoutes");
              return { Component: UnitsComp };
            },
          },
          {
            path: "units/:moduleId",
            lazy: async () => {
              const { PagesComp } = await import("./Routes/ModuleRoutes");
              return { Component: PagesComp };
            },
          },
          {
            path: "review/:moduleId",
            lazy: async () => {
              const { ReviewsComp } = await import("./Routes/ModuleRoutes");
              return { Component: ReviewsComp };
            },
          },
          {
            path: "builder/:unitId",
            lazy: async () => {
              const { CMSBuilderComp } = await import("./Routes/ModuleRoutes");
              return { Component: CMSBuilderComp };
            },
          },
          {
            path: "preview/:unitId",
            lazy: async () => {
              const { CMSPreviewWrapperComp } = await import(
                "./Routes/ModuleRoutes"
              );
              return { Component: CMSPreviewWrapperComp };
            },
          },
        ],
      },
      {
        path: "medication",
        lazy: async () => {
          const { MedicationComp } = await import("./Routes/ModuleRoutes");
          return { Component: MedicationComp };
        },
      },
      {
        path: "e-consent",
        lazy: async () => {
          const { EConsentComp } = await import("./Routes/ModuleRoutes");
          return { Component: EConsentComp };
        },
      },
      {
        path: "appointments",
        children: [
          {
            index: true,
            lazy: async () => {
              const { AppointmentsComp } = await import("./Routes/StudyRoutes");
              return { Component: AppointmentsComp };
            },
          },
          {
            path: "list",
            lazy: async () => {
              const { AppointmentListViewComp } = await import(
                "./Routes/StudyRoutes"
              );
              return { Component: AppointmentListViewComp };
            },
          },
        ],
      },
      {
        path: "my-availability",
        lazy: async () => {
          const { MyAvailabilityComp } = await import("./Routes/StudyRoutes");
          return { Component: MyAvailabilityComp };
        },
      },
      {
        path: "messages",
        lazy: async () => {
          const { MessagesComp } = await import("./Routes/StudyRoutes");
          return { Component: MessagesComp };
        },
      },
    ],
  },
  {
    path: "/telehealth/:studyId/:apptId",
    lazy: async () => {
      const { OpenTokComp } = await import("./Routes/StudyRoutes");
      return { Component: OpenTokComp };
    },
  },
  {
    path: "/lesson/read/:unitId",
    element: <WebviewWrapper />,
  },
  {
    path: "/",
    element: <Navigate to="/app/studies" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/app/studies" replace />,
  },
]);

export default routes;
