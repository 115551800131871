import { Backdrop, Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Redux/hooks";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { setPlatformPermissions } from "../../Redux/reducers/userSlice";
// import "../../utils/firebaseInit";

const AppLayout = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchPlatformPermissions = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`platform/roles/self-rights`);
        const data = res.data.data;
        const { permissions } = data;
        dispatch(setPlatformPermissions(permissions));
      } catch (e) {
        errorToastMessage(e as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlatformPermissions();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPlatformPermissions([]));
    };
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Sidebar />
      <Box
        sx={{
          flex: 1,
          minWidth: "1px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            // px: "40px",
            // py: "24px",
            flex: 1,
            minHeight: "1px",
            backgroundColor: "#FFF6EB",
            overflow: "auto",
          }}
        >
          {loading ? (
            <Backdrop open={loading}>
              <CircularProgress />
            </Backdrop>
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
