import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FoodState {
  urlLoaded: boolean;
  loading: boolean;
  searchText: string;
  page: number;
  type: string;
  templateData: any[];
  templateCount: number;
  toggler: boolean;
  templateModalId: string | null;
}

const initialState: FoodState = {
  urlLoaded: false,
  loading: true,
  searchText: "",
  page: 0,
  type: "recipes",
  templateData: [],
  templateCount: 0,
  toggler: false,
  templateModalId: null,
};

export const foodSlice = createSlice({
  name: "food",
  initialState: initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTemplateLoader: (
      state,
      action: PayloadAction<{ loadState: boolean }>
    ) => {
      state.loading = action.payload.loadState;
    },
    setTemplateData: (state, action: PayloadAction<any>) => {
      state.templateData = action.payload;
    },
    setFoodPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotalTemplateData: (state, action: PayloadAction<number>) => {
      state.templateCount = action.payload;
    },
    setFoodSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 0;
    },
    setFoodType: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = "";
      state.type = action.payload;
    },
    setFoodDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
        type: string;
      }>
    ) => {
      state.searchText = action.payload.search;
      state.page = action.payload.page;
      state.type = action.payload.type;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setTemplateModalId: (state, action: PayloadAction<string | null>) => {
      state.templateModalId = action.payload;
    },
    toggleFoodTemplates: (state) => {
      state.toggler = !state.toggler;
    },
    reset: () => initialState,
  },
});

export const {
  setLoader,
  setFoodSearchText,
  setUrlLoadedFalse,
  setTemplateModalId,
  toggleFoodTemplates,
  setFoodDefaults,
  reset,
  setTemplateData,
  setTotalTemplateData,
  setFoodPage,
  setFoodType,
} = foodSlice.actions;

export default foodSlice.reducer;
