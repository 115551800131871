import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface UserState {
  isUserAuthenticated: boolean;
  userId: string;
  role: string;
  platformPermissions: any[];
}

const initialState: UserState = {
  isUserAuthenticated: false,
  userId: "",
  role: "",
  platformPermissions: [],
};

const getIntialState = (): UserState => {
  let isUserAuthenticated = false;
  let role = "";
  let userId = "";
  const token = localStorage.getItem("sm-access-token") ?? "";
  if (token) {
    try {
      userId = localStorage.getItem("user-id") ?? "";
      isUserAuthenticated = true;
    } catch (err) {}
  }
  return {
    ...initialState,
    isUserAuthenticated: isUserAuthenticated,
    userId: userId,
    role: role,
    platformPermissions: [],
  };
};

export const userSlice = createSlice({
  name: "user",
  initialState: getIntialState,
  reducers: {
    setUserAuth: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        role: string;
        userId: string;
      }>
    ) => {
      state.role = action.payload.role;
      state.isUserAuthenticated = action.payload.authenticated;
      state.userId = action.payload.userId;
    },
    setPlatformPermissions: (state, action: PayloadAction<any[]>) => {
      state.platformPermissions = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setUserAuth, setPlatformPermissions, reset } = userSlice.actions;

export default userSlice.reducer;
