import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  FeaturesIcon,
  HomeIcon,
  MahaloCareLogo,
  PlatformUsersIcon,
} from "../Common/assets/Sidebar";
import VersionWrapper from "../Common/UI/VersionWrapper";
import { useAppSelector } from "../../Redux/hooks";
import { useMemo } from "react";
import { platformAdminPermissions } from "../../utils/roles";
// import { setCollapsed } from "../../Redux/reducers/appSlice";

const Sidebar = () => {
  const platformPermissions = useAppSelector(
    (state) => state.user.platformPermissions
  );
  const rbac = useMemo(
    () => ({
      manage: platformPermissions.includes(
        platformAdminPermissions.managePlatform
      ),
      view: platformPermissions.includes(platformAdminPermissions.viewPlatform),
    }),
    [platformPermissions]
  );

  return (
    <Box
      className={`sidebar`}
      sx={{
        "& .sider-menu": {
          color: "text.secondary",
        },
        "& .active.sider-menu": {
          backgroundColor: "secondary.main",
        },
        "& .user-management-menu": {
          color: "text.secondary",
        },
        "& .active.user-management-menu": {
          color: (theme) => theme.palette.primary.main,
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            margin: "10px 0px",
          }}
        >
          <MahaloCareLogo />
        </Box>
        <NavLink
          to="/app/studies"
          title="My Programs"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <HomeIcon />
          <Typography variant="subtitle1">My Programs</Typography>
        </NavLink>
        {/* 
        <NavLink
          to="/app/organisation"
          title="Organisation"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          {!collapsed && (
            <Typography variant="subtitle1">Organisation</Typography>
          )}
        </NavLink> */}
        {rbac.view && (
          <NavLink
            to="/app/features"
            title="Features"
            className={({ isActive }) =>
              isActive ? "sider-menu active" : "sider-menu"
            }
          >
            <FeaturesIcon />
            <Typography variant="subtitle1">Features</Typography>
          </NavLink>
        )}
        {rbac.view && (
          <NavLink
            to="/app/platform/users"
            title="Users"
            className={({ isActive }) =>
              isActive ? "sider-menu active" : "sider-menu"
            }
          >
            <PlatformUsersIcon />
            <Typography variant="subtitle1">Users</Typography>
          </NavLink>
        )}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton onClick={toggleCollapse}>
          {collapsed ? (
            <ChevronRight fontSize="large" />
          ) : (
            <ChevronLeft fontSize="large" />
          )}
        </IconButton>
      </Box> */}
      <VersionWrapper />
    </Box>
  );
};

export default Sidebar;
